jQuery(function($){
    getSessionStorage();
    initAjaxBtn();
    function initAjaxBtn() {
        $('#ajax-btn').off('click');
        $('#ajax-btn').on('click', function(e){
            e.preventDefault();

            var body = $('body');
            var offset = false;
            var s = '';
            var postType = '';
            var categorie = '';
            if (body.hasClass('page-template-actualites')) {
                var action = 'loadmorePosts';
                var category = $('#categories .category.current').attr('data-category');
            } else if (body.hasClass('page-template-contribuer')) {
                var action = 'loadmoreContributions';
                offset = $(this).attr('data-offset');
            } else if (body.hasClass('search-results')) {
                var action = 'loadmoreSearchs';
                s = $('#search-parameters').attr('data-s');
                postType = $('#search-parameters').attr('data-post_type');
            }


            var button = $(this),
                data = {
                'action': action,
                'query': loadmore_params.posts,
                'page': loadmore_params.current_page,
                'offset': offset,
                'categorie': category,
                's': s,
                'post_type': postType
            };

            $.ajax({
                url: loadmore_params.ajaxurl,
                data: data,
                type: 'POST',
                beforeSend: function ( xhr ) {
                    button.addClass('is-loading');
                    button.prop("disabled",true);
                },
                success: function( data ){
                    if(data.replace(/\s/g, '').length) { 
                        button.removeClass('is-loading').closest('div').prev().after(data);
                        loadmore_params.current_page++;
                        button.prop("disabled", false);

                        if ( loadmore_params.current_page == loadmore_params.max_page )
                            button.remove();
                    } else {
                        button.remove();
                    }
                }
            });
        });
    }
    if($('body').hasClass('page-template-actualites')){
        
        function loadAjaxActualites(data, url){
			$.ajax({
				url: loadmore_params.ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					$('#post-container').remove();
					$('#onload').removeClass('d-none');
				},
				success: function( data ){
					if( data ) {
                        $('#onload').addClass('d-none');
						$('#ajax-container').html(data);
                        initAjaxBtn();
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
					}
				}
			});
		}
        
        // categories
        $(document).on('click','#categories .category',function(e){
			e.preventDefault();
            
            $('.category.current').removeClass('current').attr('aria-expanded', false);
            $(this).addClass('current').attr('aria-expanded', true);
            
            var category = $(this).attr('data-category');
            var url = $(this).attr('href');
            
            var params = [
                {
                    'name': 'categorie',
                    'value': category
                }
            ];
            
            var link = getUrl(params);
            
			var data = {
				'action': 'loadActualites',
                'paged': 1,
				'categorie': category,
                'link': link
            };
            loadAjaxActualites(data, url);
		});
        
        // pagination
        $(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
			
			var paged = $(this).attr('data-page');
         
            var category = $('#categories .category.current').attr('data-category');
            var url = $(this).attr('href');
            
            var params = [
                {
                    'name': 'categorie',
                    'value': category
                }
            ];
            
            var link = getUrl(params);
            
            var data = {
				'action': 'loadActualites',
				'paged': paged,
                'categorie': category,
                'link': link
            };
            loadAjaxActualites(data, url);
		});
    }

	if($('body').hasClass('page-template-aides')){
        
        setSessionStorage('aidesParams');
		
		function loadAjaxAides(data,container, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					container.find('.results').slideUp(700,function(){
						$(this).remove();
					}).fadeOut();
					container.find('.header-results .onload').removeClass('d-none');
					$('form.filters button').addClass('is-loading');
					
                    $('html, body').animate({
                        scrollTop: $("main .section-results").offset().top - 120 
                      }, 500);
				},
				success: function( data ){
					if( data ) {
						$('form.filters button').removeClass('is-loading');
						container.find('.results').remove();//On est sur que d'autres résultats ne sont pas présents
						container.append(data);
						container.find('.results').slideUp(0).slideDown(2000);
						container.find('.header-results .onload').addClass('d-none');
						container.find('.header-results .nb_results').html(container.find('.results').attr('data-nb-reults'));
                        container.trigger('change');
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
                        setSessionStorage('aidesParams');
                        
                        $('html, body').animate({
                            scrollTop: $("main .section-results").offset().top - 120 
                        }, 500);
					} else {
						//button.remove();
					}
				}
			});
		}
        
        function setParamsAides(params, motClef, profil, objectif, thematique, cloture, showall, programmation, fonds, url) {
            if (motClef != null && motClef.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': motClef
                })
            }
            if (profil != null && profil.length) {
                url += '&profil='+profil;
                
                params.push({
                    'name': 'profil',
                    'value': profil
                })
            }
            if (objectif != null && objectif.length) {
                url += '&objectif='+objectif;
                
                params.push({
                    'name': 'objectif',
                    'value': objectif
                })
            }
            if (thematique != null && thematique.length) {
                url += '&thematique='+thematique;
                
                params.push({
                    'name': 'thematique',
                    'value': thematique
                })
            }
            if (cloture != null && cloture.length) {
                url += '&cloture='+cloture;
                
                params.push({
                    'name': 'cloture',
                    'value': cloture
                })
            }
            if (showall != null && showall.length) {
                url += '&showall='+showall;
                
                params.push({
                    'name': 'showall',
                    'value': showall
                })
            }
            if (programmation != null && programmation.length) {
                url += '&programmation='+programmation;
                
                params.push({
                    'name': 'programmation',
                    'value': programmation
                })
            }
            if (fonds != null && fonds.length) {
                url += '&fonds='+fonds;
                
                params.push({
                    'name': 'fonds',
                    'value': fonds
                })
            }
            
            return [url, params];
        }
        
		$('form.filters-aides').submit(function(event){
			event.preventDefault();
            var motClef = $('form.filters input[name="mot-clef"]').val();
            if (motClef == null) {
                motClef = '';
            }
            var profil = $('form.filters select[name="profil"]').val();
            var objectif = $('form.filters select[name="objectif"]').val();
            var thematique = $('form.filters select[name="thematique"]').val();
            var cloture = $('form.filters input[name="cloture"]').val();
            var showall = $('form.filters input[name="showall"]').val();
            var programmation = $('form.filters input[name="programmation"]').val();
            var fonds = $('form.filters input[name="fonds"]').val();
            
            var container = $('body main .section-results>.row');
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+motClef;
            
            var params = [];            
            var paramsArray = setParamsAides(params, motClef, profil, objectif, thematique, cloture, showall, programmation, fonds, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
            var data = {
				'action': 'loadAides',
				'mot-clef':motClef,
				'profil':profil,
				'objectif':objectif,
				'thematique':thematique,
				'cloture':cloture,
				'showall':showall,
				'programmation':programmation,
				'fonds':fonds,
                'link': link
            };
            
            loadAjaxAides(data, container, url);
		});
		
		$(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
			
			var paged = $(this).attr('data-page');
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
            if (motClef == null) {
                motClef = '';
            }
            var profil = $('form.filters select[name="profil"]').val();
            var objectif = $('form.filters select[name="objectif"]').val();
            var thematique = $('form.filters select[name="thematique"]').val();
            var cloture = $('form.filters input[name="cloture"]').val();
            var showall = $('form.filters input[name="showall"]').val();
            var programmation = $('form.filters input[name="programmation"]').val();
            var fonds = $('form.filters input[name="fonds"]').val();
            
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+motClef;
            
            var params = [];            
            var paramsArray = setParamsAides(params, motClef, profil, objectif, thematique, cloture, showall, programmation, fonds, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadAides',
				'mot-clef': motClef,
				'profil': profil,
				'objectif': objectif,
				'thematique': thematique,
				'cloture': cloture,
				'showall': showall,
				'programmation': programmation,
				'fonds': fonds,
				'paged': paged,
                'link': link
				},
				container = $('body main .section-results>.row');
				loadAjaxAides(data, container, url);
		});
		
		var paged=1;
		$(document).on('click','.loadmore',function(e){
			e.preventDefault();
			var data = {
				'action': 'loadAides',
				'mot-clef':$('form.filters input[name="mot-clef"]').val(),
				'profil':$('form.filters select[name="profil"]').val(),
				'objectif':$('form.filters select[name="objectif"]').val(),
				'thematique':$('form.filters select[name="thematique"]').val(),
				'cloture':$('form.filters input[name="cloture"]').val(),
				'showall':$('form.filters input[name="showall"]').val(),
				'programmation':$('form.filters input[name="programmation"]').val(),
				'fonds':$('form.filters input[name="fonds"]').val(),
				'paged': ++paged
				},
				button = $(this),
				container = $('body main .section-results>.row');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					button.addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						button.removeClass('is-loading');
						data = $(data).find('.teaser');
						if(data.length>0){
							$('<div class="content" tabindex="-1">').insertAfter(container.find('.content').last());
							container.find('.content').last().append(data);
						}else{
							button.remove();
						}
					} else {
						button.remove();
					}
				}
			});
		});
	}
	
	if($('body').hasClass('page-template-offres-emplois')){
        
        setSessionStorage('emploisParams');
		
		function loadAjaxOffres(data,container, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					container.find('.results').slideUp(700,function(){
						$(this).remove();
					}).fadeOut();
					container.find('.header-results .onload').removeClass('d-none');
					$('form.filters button').addClass('is-loading');
					
                    $('html, body').animate({
                        scrollTop: $("main .section-results").offset().top - 120 
                    }, 500);
				},
				success: function( data ){
					if( data ) {
						$('form.filters button').removeClass('is-loading');
						container.find('.results').remove();//On est sur que d'autres résultats ne sont pas présents
						container.append(data);
						container.find('.results').slideUp(0).slideDown(2000);
						container.find('.header-results .onload').addClass('d-none');
						container.find('.header-results .nb_results').html(container.find('.results').attr('data-nb-reults'));
                        container.trigger('change');

                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
                        setSessionStorage('emploisParams');
					} else {
						//button.remove();
					}
				}
			});
		}
        function setParamsOffres(params, motClef, type, famille, categorie, localisation, url) {
            if (motClef != null && motClef.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': motClef
                })
            }
            if (type != null && type.length) {
                url += '&type='+type;
                
                params.push({
                    'name': 'type',
                    'value': type
                })
            }
            if (famille != null && famille.length) {
                url += '&famille='+famille;
                
                 params.push({
                    'name': 'famille',
                    'value': famille
                })
            }
            if (categorie != null && categorie.length) {
                url += '&categorie='+categorie;
                
                 params.push({
                    'name': 'categorie',
                    'value': categorie
                })
            }
            if (localisation != null && localisation.length) {
                url += '&localisation='+localisation;
                
                 params.push({
                    'name': 'localisation',
                    'value': localisation
                })
            }
            
            return [url, params];
        }
        
		$('form.filters-aides').submit(function(event){
			event.preventDefault();
			
			var motClef = $('form.filters input[name="mot-clef"]').val();
			var type = $('form.filters select[name="type"]').val();
			var famille = $('form.filters select[name="famille"]').val();
			var categorie = $('form.filters select[name="categorie"]').val();
			var localisation = $('form.filters select[name="localisation"]').val();
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+motClef;            
			
            var params = [];            
            var paramsArray = setParamsOffres(params, motClef, type, famille, categorie, localisation, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
			
			var data = {
				'action': 'loadOffres',
				'mot-clef':motClef,
				'type':type,
				'famille':famille,
				'categorie':categorie,
				'localisation':localisation,
                'link': link
				},
				container = $('body main .section-results>.row');
				if(motClef !='' || type != null || famille != null || categorie!= null || localisation != null){
					$('form.filters .error').hide();
					loadAjaxOffres(data,container, url);
				}else{
					$('form.filters .error').removeClass('d-none').show();
				}
				
				
		});
		
		$(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var type = $('form.filters select[name="type"]').val();
			var famille = $('form.filters select[name="famille"]').val();
			var categorie = $('form.filters select[name="categorie"]').val();
			var localisation = $('form.filters select[name="localisation"]').val();
			
			var paged = $(this).attr('data-page');
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+motClef;
            
            var params = [];            
            var paramsArray = setParamsOffres(params, motClef, type, famille, categorie, localisation, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadOffres',
				'mot-clef': motClef,
				'type': type,
				'famille': famille,
				'categorie': categorie,
				'localisation': localisation,
				'paged': paged,
                'link': link
				},
				container = $('body main .section-results>.row');
				loadAjaxOffres(data,container, url);
		});
		
		var paged=1;
		$(document).on('click','.loadmore',function(e){
			e.preventDefault();
			var data = {
				'action': 'loadOffres',
				'mot-clef':$('form.filters input[name="mot-clef"]').val(),
				'type':$('form.filters select[name="type"]').val(),
				'famille':$('form.filters select[name="famille"]').val(),
				'categorie':$('form.filters select[name="categorie"]').val(),
				'localisation':$('form.filters input[name="localisation"]').val(),
				'paged': ++paged
				},
				button = $(this),
				container = $('body main .section-results>.row');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					button.addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						button.removeClass('is-loading');
						data = $(data).find('.teaser');
						if(data.length>0){
							$('<div class="content" tabindex="-1">').insertAfter(container.find('.content').last());
							container.find('.content').last().append(data);
						}else{
							button.remove();
						}
					} else {
						button.remove();
					}
				}
			});
		});
	}
	
	if($('body').hasClass('page-template-documents')){
        setSessionStorage('documentsParams');
		
		function loadAjaxDocuments(data,container, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					container.find('.results').slideUp(700,function(){
						$(this).remove();
					}).fadeOut();
					container.find('.header-results .onload').removeClass('d-none');
					$('form.filters button').addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						$('form.filters button').removeClass('is-loading');
						container.find('.results').remove();//On est sur que d'autres résultats ne sont pas présents
						container.append(data);
						$('html, body').animate({
		                    scrollTop: $("main>.section-results").offset().top - 120 
			              }, 500);
						container.find('.results').slideUp(0).slideDown(2000);
						container.find('.header-results .onload').addClass('d-none');
						container.find('.header-results .nb_results').html(container.find('.results').attr('data-nb-reults'));
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
                        setSessionStorage('documentsParams');
					} else {
						//button.remove();
					}
				}
			});
		}
        function setParamsDocuments(params, tri, url, motClef, typeDoc, thematique, session, categorie, type) {
            if (motClef != null && motClef.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': motClef
                })
            }
            if (typeDoc != null && typeDoc.length) {
                url += '&type-de-doc='+typeDoc;
                
                params.push({
                    'name': 'type-de-doc',
                    'value': typeDoc
                })
            }
            if (thematique != null && thematique.length) {
                url += '&thematique='+thematique;
                
                params.push({
                    'name': 'thematique',
                    'value': thematique
                })
            }
            if (session != null && session.length) {
                url += '&session='+session;
                
                params.push({
                    'name': 'session',
                    'value': session
                })
            }
            if (categorie != null && categorie.length) {
                url += '&categorie='+categorie;
                
                params.push({
                    'name': 'categorie',
                    'value': categorie
                })
            }
            if (type != null && type.length) {
                url += '&type='+type;
                
                params.push({
                    'name': 'type',
                    'value': type
                })
            }
            if (tri != null && tri.length) {
                url += '&tri='+tri;
                
                params.push({
                    'name': 'tri',
                    'value': tri
                })
            }
            return [url, params];
        }
        
		$('form.filters').on('submit', function(event){
			event.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var type = $('form.filters select[name="type"]').val();
			var typeDoc = $('form.filters select[name="type-de-doc"]').val();
			var thematique = $('form.filters select[name="thematique"]').val();
			var session = $('form.filters select[name="session"]').val();
			var tri = $('form.filters input[name="tri"]').val();
            var categorie = $('form.filters select[name="categorie"]').val();
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+motClef;

            var params = [];
            var paramsArray = setParamsDocuments(params, tri, url, motClef, typeDoc, thematique, session, categorie, type);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadDocuments',
				'mot-clef': motClef,
				'type': type,
				'type-de-doc': typeDoc,
				'thematique': thematique,
				'session': session,
				'tri': tri,
                'categorie': categorie,
                'link': link
				},
				container = $('body main .section-results>.row'); 
				loadAjaxDocuments(data,container, url);
		});
        
        
        //Clic sur les tris
        $('.header-sort .tri').click(function(){
           $(this).toggleClass('active');
           if($(this).hasClass('active')){
               $(this).text($(this).attr('attr-asc'));
               $('form.filters input[name="tri"]').attr('value','asc');
           }else{
               $(this).text($(this).attr('attr-desc'));
               $('form.filters input[name="tri"]').attr('value','desc');
           }
           $('form.filters').trigger('submit');
        });
		
		$(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var type = $('form.filters select[name="type"]').val();
			var typeDoc = $('form.filters select[name="type-de-doc"]').val();
			var thematique = $('form.filters select[name="thematique"]').val();
			var session = $('form.filters select[name="session"]').val();
			var tri = $('form.filters input[name="tri"]').val();
            var categorie = $('form.filters select[name="categorie"]').val();
            
			var paged = $(this).attr('data-page');
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+motClef;
            
            var params = [];            
            var paramsArray = setParamsDocuments(params, tri, url, motClef, typeDoc, thematique, session, categorie, type);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadDocuments',
				'mot-clef': motClef,
				'type': type,
				'type-de-doc': typeDoc,
				'thematique': thematique,
				'session': session,
				'tri': tri,
                'categorie': categorie,
				'paged': paged,
                'link': link
				},
				container = $('body main .section-results>.row');
				loadAjaxDocuments(data,container, url);
		});
		
		var paged=1;
		$(document).on('click','.loadmore',function(e){ 
			e.preventDefault();
			var data = {
				'action': 'loadDocuments',
				'mot-clef':$('form.filters input[name="mot-clef"]').val(),
				'type':$('form.filters select[name="type"]').val(),
				'type-de-doc':$('form.filters select[name="type-de-doc"]').val(),
				'thematique':$('form.filters select[name="thematique"]').val(),
				'session':$('form.filters select[name="session"]').val(),
				'tri':$('form.filters input[name="tri"]').val(),
                'categorie':$('form.filters select[name="categorie"]').val(),
				'paged': ++paged
				},
				button = $(this),
				container = $('body main .section-results>.row');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					button.addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						button.removeClass('is-loading');
						data = $(data).find('.teaser');
						if(data.length>0){
							$('<div class="content" tabindex="-1">').insertAfter(container.find('.content').last());
							container.find('.content').last().append(data);
						}else{
							button.remove();
						}
					} else {
						button.remove();
					}
				}
			});
		});
	}
    
    if($('body').hasClass('page-template-annuaire-elus')){
        setSessionStorage('elusParams');
        
		function loadAjaxElus(data,container, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					$('#post-container').hide();
					$('#onload').removeClass('d-none');
					$('form.filters button').addClass('is-loading');
					$('.pagination').remove();
				},
				success: function( data ){
					if( data ) {
                        $('#post-container').show();
						$('form.filters button').removeClass('is-loading');
						container.find('.results').remove();//On est sur que d'autres résultats ne sont pas présents
						container.append(data);
						$('html, body').animate({
		                    scrollTop: $('.section-filters').offset().top - 120 
			              }, 500);
						$('#onload').addClass('d-none');
						container.find('.header-results .nb_results').html(container.find('.results').attr('data-nb-reults'));
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
                        setSessionStorage('elusParams');
					} else {
						//button.remove();
					}
				}
			});
		}
        function setParamsElus(params, motClef, groupe, commission, college, fonction, thematique, url) {
            if (motClef != null && motClef.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': motClef
                })
            }
            if (groupe != null && groupe.length) {
                url += '&groupe='+groupe;
                
                params.push({
                    'name': 'groupe',
                    'value': groupe
                })
            }
            if (commission != null && commission.length) {
                url += '&commission='+commission;
                
                params.push({
                    'name': 'commission',
                    'value': commission
                })
            }
            if (college != null && college.length) {
                url += '&college='+college;
                
                params.push({
                    'name': 'college',
                    'value': college
                })
            }
            if (fonction != null && fonction.length) {
                url += '&fonction='+fonction;
                
                params.push({
                    'name': 'fonction',
                    'value': fonction
                })
            }
            if (thematique != null && thematique.length) {
                url += '&thematique='+thematique;
                
                params.push({
                    'name': 'thematique',
                    'value': thematique
                })
            }
            
            return [url, params];
        }
        
		$('form.filters').submit(function(event){
			event.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var groupe = $('form.filters select[name="groupe"]').val();
			var commission = $('form.filters select[name="commission"]').val();
			var college = $('form.filters select[name="college"]').val();
			var fonction = $('form.filters select[name="fonction"]').val();
            var thematique = $('form.filters select[name="thematique"]').val();
            var cpt = $('#ajax-container').attr('data-cpt');
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+motClef;

            var params = [];            
            var paramsArray = setParamsElus(params, motClef, groupe, commission, college, fonction, thematique, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadElus',
				'mot-clef': motClef,
				'groupe': groupe,
				'commission': commission,
				'college': college,
				'fonction': fonction,
                'thematique': thematique,
                'link': link,
                'cpt': cpt
				},
				container = $('#ajax-container');
				loadAjaxElus(data,container, url);
		});
		$('select[name="sort"]').change(function(){
			var value = $(this).val();
			$('form.filters input[name="tri"]').val(value);
			$('form.filters').trigger('submit');
		});
		
		$(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var groupe = $('form.filters select[name="groupe"]').val();
			var commission = $('form.filters select[name="commission"]').val();
			var college = $('form.filters select[name="college"]').val();
			var fonction = $('form.filters select[name="fonction"]').val();
            var thematique = $('form.filters select[name="thematique"]').val();
			var cpt = $('#ajax-container').attr('data-cpt');
            
			var paged = $(this).attr('data-page');
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+motClef;
            
            var params = [];            
            var paramsArray = setParamsElus(params, motClef, groupe, commission, college, fonction, thematique, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadElus',
				'mot-clef': motClef,
				'groupe': groupe,
				'commission': commission,
				'college': college,
				'fonction': fonction,
                'thematique': thematique,
				'paged': paged,
                'link': link,
                'cpt': cpt
				},
				container = $('#ajax-container');
				loadAjaxElus(data,container, url);
		});
        
        var paged=1;
		$(document).on('click','.loadmore',function(e){ 
			e.preventDefault();
            
            var motClef = $('form.filters input[name="mot-clef"]').val();
			var groupe = $('form.filters select[name="groupe"]').val();
			var commission = $('form.filters select[name="commission"]').val();
			var college = $('form.filters select[name="college"]').val();
			var fonction = $('form.filters select[name="fonction"]').val();
            var thematique = $('form.filters select[name="thematique"]').val();
			var cpt = $('#ajax-container').attr('data-cpt');
            
			var data = {
				'action': 'loadElus',
				'mot-clef': motClef,
				'groupe': groupe,
				'commission': commission,
				'college': college,
				'fonction': fonction,
                'thematique': thematique,
				'paged': ++paged,
                'cpt': cpt
				},
				button = $(this),
				container = $('#ajax-container');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					button.addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						button.removeClass('is-loading');
						data = $(data).find('.teaser');
						if(data.length>0){
                            $('<div class="content" tabindex="-1">').insertAfter(container.find('.content').last());
							container.find('.content').last().append(data);
						}else{
							button.remove();
						}
					} else {
						button.remove();
					}
				}
			});
		});
	}
    
    if($('body').hasClass('page-template-agenda-president')){
        var ajaxContainer = $('#ajax-container');
        
        $(document).on('click', '.ajax-btn', function(e) {
            e.preventDefault();
            
            var startDate = $(this).attr('data-startDate');
            var url = window.location.href.split('?')[0];
            url += '?startDate=' + startDate;
            
            var data = {
				'action': 'loadEvenements',
                'startDate': startDate
				},
				container = $('body main .section-results>.row');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
                    $('#onload').removeClass('d-none');
                    $('.bloc-evenements .wrapper').html('');
				},
				success: function( data ){
					if( data ) {
						if(data.length>0){
                            ajaxContainer.html(data);
                            $('#onload').addClass('d-none');
                            
                            if (url != null) {                            
                                window.history.pushState('', '', url);
                            }
						}
					}
				}
			});
        });
    }

    if($('body').hasClass('page-template-agenda-presse')){
        var ajaxContainer = $('#ajax-container');
        
        $(document).on('click', '.ajax-btn', function(e) {
            e.preventDefault();
            
            var startDate = $(this).attr('data-startDate');
            var url = window.location.href.split('?')[0];
            url += '?startDate=' + startDate;
            
            var data = {
				'action': 'loadEvenementsPresse',
                'startDate': $(this).attr('data-startDate')
				},
				container = $('body main .section-results>.row');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
                    $('#onload').removeClass('d-none');
                    $('.bloc-agenda-presse .wrapper').html('');
				},
				success: function( data ){
					if( data ) {
						if(data.length>0){
                            ajaxContainer.html(data);
                            $('#onload').addClass('d-none');
                            
                            if (url != null) {                            
                                window.history.pushState('', '', url);
                            }
						}
					}
				}
			});
        });
    }

    if($('body').hasClass('search-results')){
        
        function loadAjaxSearch(data, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function (xhr) {
					$('#onload').removeClass('d-none');
				},
				success: function(data){
					if(data) {
                        $('#onload').addClass('d-none');
						$('#ajax-container').html(data);
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
                        initSearchFilters();
                        initAjaxBtn();
					}
				}
			});
		}
        function setParamsSearch(params, s, post_type, cpt_filter, profil, thematique, objectif, parObjectif, permalinkPrefix, url) {
            if (s != null && s.length) {
                params.push({
                    'name': 's',
                    'value': s
                })
            }
            if (post_type != null && post_type.length) {
                url += '&post_type='+post_type;
                
                params.push({
                    'name': 'post_type',
                    'value': post_type
                })
            }
            if (cpt_filter != null && cpt_filter.length) {
                url += '&cpt_filter='+cpt_filter;
                
                params.push({
                    'name': 'cpt_filter',
                    'value': cpt_filter
                })
            }
            if (profil != null && profil.length) {
                url += '&profil='+profil;
                
                params.push({
                    'name': 'profil',
                    'value': profil
                })
            }
            if (thematique != null && thematique.length) {
                url += '&thematique='+thematique;
                
                params.push({
                    'name': 'thematique',
                    'value': thematique
                })
            }
            if (objectif != null && objectif.length) {
                url += '&objectif='+objectif;
                
                params.push({
                    'name': 'objectif',
                    'value': objectif
                })
            }
            if (parObjectif != null && parObjectif.length) {
                url += '&parObjectif='+parObjectif;
                
                params.push({
                    'name': 'parObjectif',
                    'value': parObjectif
                })
            }
            if (permalinkPrefix != null && permalinkPrefix.length) {
                url += '&permalink_prefix='+permalinkPrefix;
                
                params.push({
                    'name': 'permalink_prefix',
                    'value': permalinkPrefix
                })
            }
            
            return [url, params];
        }
        
        // pagination
        $(document).on('click','.page-numbers li a',function(e) {
			e.preventDefault();
			
            $("html, body").animate({ scrollTop: 0 }, 300);
			var paged = $(this).attr('data-page');
            var s = $('#search-parameters').attr('data-s');
            var post_type = $('#search-parameters').attr('data-post_type');
            $('.pagination .page-numbers .current').removeClass('current').attr('aria-expanded', false);
            $('.slide-container').slideUp();
            $(this).closest('li').addClass('current').attr('aria-expanded', true);
            
            var filter = $('#toggle_champ').attr('data-filter');
            
            var cpt_filter = $('select[name="type_filter"]').val();
            var profil = $('select[name="profil"]').val();
            var thematique = $('select[name="thematique"]').val();
            var objectif = $('select[name="objectif"]').val();
            var parObjectif = 0;
            var permalinkPrefix = $('input[name="permalink_prefix"]').attr('value');

            if (filter == 'thematique') {
                objectif = '';
            } else if (filter == 'objectif') {
                thematique = '';
                parObjectif = 1;
            }
            
            var url = $(this).attr('href').split('?')[0]+'?s='+s;

            var params = [];            
            var paramsArray = setParamsSearch(params, s, post_type, cpt_filter, profil, thematique, objectif, parObjectif, permalinkPrefix, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
            var data = {
				'action': 'loadSearchs',
				'paged': paged,
                's': s,
                'post_type': post_type,
                'cpt_filter': cpt_filter,
                'profil': profil,
                'thematique': thematique,
                'objectif': objectif,
                'parObjectif': parObjectif,
                'permalink_prefix': permalinkPrefix,
                'link': link
            };
            loadAjaxSearch(data, url);
		});
        
        // filtres des aides
        initSearchFilters();
        function initSearchFilters() {
            $('.results-container select').on('changed.bs.select', function(e, clickedIndex, isSelected, previousValue) {            
                var value = $(this).val();
                var name = $(this).attr('name');
                
                if (value != null) {                    
                    var paged = 1;
                    
                    var filter = $('#toggle_champ').attr('data-filter');
                    
                    var cpt_filter = $('select[name="type_filter"]').val();
                    var profil = $('select[name="profil"]').val();
                    var thematique = $('select[name="thematique"]').val();
                    var objectif = $('select[name="objectif"]').val();
                    var parObjectif = 0;
                    var permalinkPrefix = $('input[name="permalink_prefix"]').attr('value');
                    
                    if (filter == 'thematique') {
                        objectif = '';
                    } else if (filter == 'objectif') {
                        thematique = '';
                        parObjectif = 1;
                    }

                    var s = $('#search-parameters').attr('data-s');
                    var post_type = $('#search-parameters').attr('data-post_type');
                    $('.id-ajax-filters').slideUp();
                    
                    var url = window.location.href.split('?')[0].split('page/')[0]+'?s='+s;
                    
                    var params = [];            
                    var paramsArray = setParamsSearch(params, s, post_type, cpt_filter, profil, thematique, objectif, parObjectif, permalinkPrefix, url);
                    url = paramsArray[0];
                    params = paramsArray[1];
                    var link = getUrl(params);
                    
                    var data = {
                        'action': 'loadSearchs',
                        'paged': paged,
                        's': s,
                        'post_type': post_type,
                        'cpt_filter': cpt_filter,
                        'profil': profil,
                        'thematique': thematique,
                        'objectif': objectif,
                        'parObjectif': parObjectif,
                        'link': link,
                        'permalink_prefix': permalinkPrefix
                    };
                    loadAjaxSearch(data, url);
                }
            });
            
            $('#toggle_champ').click(function() {
               var txt = $(this).text();
               var toggle = $(this).attr('attr-toggle');
                var results = $('.results-container');

               $(this).text(toggle);
               $(this).attr('attr-toggle',txt);
               $(this).toggleClass('thematique');

               if ($(this).hasClass('thematique')) {
                    results.find('.champ_objectif').removeClass('d-none').siblings().addClass('d-none');
                    $(this).attr('data-filter', 'objectif');
               } else {
                    results.find('.champ_thematique').removeClass('d-none').siblings().addClass('d-none');
                   $(this).attr('data-filter', 'thematique');
               }
               results.find('.champs_dynamique>div:not(.active) .selectpicker').selectpicker('val', '');
            });
        }
        
        // filtres et boutons
        $(document).on('click','#ajax-container .cptBtn',function(e) {
			e.preventDefault();
			
			var paged = 1;
            var s = $('#search-parameters').attr('data-s');
            var post_type = $(this).attr('data-post_type');
            var url = $(this).attr('data-href').split('?')[0].split('page/')[0]+'?s='+s;
            var permalinkPrefix = $('input[name="permalink_prefix"]').attr('value');
            $('#search-filters button').removeClass('category');
            $('.pagination').slideUp();
            $('.slide-container').slideUp();
            $(this).addClass('category');
            
            var params = [];            
            var paramsArray = setParamsSearch(params, s, post_type, '', '', '', '', '', permalinkPrefix, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
            var data = {
				'action': 'loadSearchs',
				'paged': paged,
                's': s,
                'post_type': post_type,
                'link': link,
                'permalink_prefix': permalinkPrefix
            };
            loadAjaxSearch(data, url);
            $(this).focus();
		});
        
        // permalink_prefix
        if ($('body').hasClass('search-results') || $('body').hasClass('search-no-results')) {
            var tagContainer = $('.search-tag-container');
            var tagClose = $('.search-tag-close');
            var permalinkPrefixInputs = $('input[name="permalink_prefix"]');
            
            $(document).on('click','.search-tag-close',function(e) {
                $('input[name="permalink_prefix"]').remove();
                $('.search-tag-container').remove();
                var splittedHref = $('#ajax-container .cptBtn').attr('data-href').split('&permalink_prefix')[0];
                $('#ajax-container .cptBtn').attr('data-href', splittedHref);
                $('#ajax-container .cptBtn.category').trigger('click');
            });
        }
    }
    // Communiqués de presse
    if($('body').hasClass('page-template-communiques')){
        
        function loadAjaxCommuniques(data, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					$('#ajax-container').empty();
					$('#onload').removeClass('d-none');
				},
				success: function( data ){
					if( data ) {
                        $('#onload').addClass('d-none');
						$('#ajax-container').html(data);
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
					}
				}
			});
		}
        function setParamsCommuniques(params, query, categorie, url) {
            if (query != null && query.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': query
                })
            }
            if (categorie != null && categorie.length) {
                url += '&categorie='+categorie;
                
                params.push({
                    'name': 'categorie',
                    'value': categorie
                })
            }
            
            return [url, params];
        }
        
        // categories
        $(document).on('click','#categories .category',function(e){
			e.preventDefault();
            
            $('.category.current').removeClass('current').attr('aria-expanded', false);
            $(this).addClass('current').attr('aria-expanded', true);
            
            var category = $(this).attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = window.location.href.split('?')[0].split('page/')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsCommuniques(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadCommuniques',
                'paged': 1,
                'mot-clef': query,
				'categorie': category,
                'link': link
            };
            loadAjaxCommuniques(data, url);
		});
        
        // pagination
        $(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();            
			
			var paged = $(this).attr('data-page');
         
            var category = $('#categories .category.current').attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsCommuniques(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
            var data = {
				'action': 'loadCommuniques',
				'paged': paged,
                'categorie': category,
                'mot-clef': query,
                'link': link
            };
            loadAjaxCommuniques(data, url);
		});
        
        // recherche
        $('form.filters-communiques').submit(function(event){
			event.preventDefault();
            
            var category = $('#categories .category.current').attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsCommuniques(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadCommuniques',
				'paged': paged,
                'categorie': category,
                'mot-clef': query,
                'link': link
            };
            loadAjaxCommuniques(data, url);
		});
        
        var paged=1;
		$(document).on('click','.loadmore',function(e){
			e.preventDefault();
			var data = {
				'action': 'loadMoreCommuniques',
                'mot-clef': $('form.filters input[name="mot-clef"]').val(),
				'categorie': $('#categories .category.current').attr('data-category'),
				'paged': ++paged
				},
				button = $(this),
				container = $('#ajax-container');
				
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					button.addClass('is-loading');
					
				},
				success: function( data ){
					if( data ) {
						button.removeClass('is-loading');
						if(data.replace(/\s/g, '').length){
                            $('#post-container').append(data);
						}else{
							button.remove();
						}
					} else {
						button.remove();
					}
				}
			});
		});
    }
    // Paroles président
    if($('body').hasClass('page-template-paroles-president')){
        
        function loadAjaxParoles(data, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					$('#paroles-container').remove();
					$('#onload').removeClass('d-none');
				},
				success: function( data ){
					if( data ) {
                        $('#onload').addClass('d-none');
						$('#ajax-container').html(data);
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
					}
				}
			});
		}
        function setParamsPresident(params, query, categorie, url) {
            if (query != null && query.length) {
                params.push({
                    'name': 'mot-clef',
                    'value': query
                })
            }
            if (categorie != null && categorie.length) {
                url += '&categorie='+categorie;
                
                params.push({
                    'name': 'categorie',
                    'value': categorie
                })
            }
            
            return [url, params];
        }
        
        // categories
        $(document).on('click','#categories .category',function(e){
			e.preventDefault();
            
            $('.category.current').removeClass('current').attr('aria-expanded', false);
            $(this).addClass('current').attr('aria-expanded', true);
            
            var category = $(this).attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = window.location.href.split('?')[0].split('page/')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsPresident(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadParoles',
                'paged': 1,
				'categorie': category,
                'mot-clef': query,
                'link': link
            };
            loadAjaxParoles(data, url);
		});
        
        // pagination
        $(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
			
			var paged = $(this).attr('data-page');
         
            var category = $('#categories .category.current').attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = $(this).attr('href').split('?')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsPresident(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
            var data = {
				'action': 'loadParoles',
				'paged': paged,
                'categorie': category,
                'mot-clef': query,
                'link': link
            };
            loadAjaxParoles(data, url);
		});
        
        // recherche
        $('form.filters-paroles-president').submit(function(event){
			event.preventDefault();
            
            var category = $('#categories .category.current').attr('data-category');
            var query = $('form.filters input[name="mot-clef"]').val();
            
            var url = $(this)[0]['action'].split('?')[0].split('page/')[0]+'?mot-clef='+query;
            
            var params = [];            
            var paramsArray = setParamsPresident(params, query, category, url);
            url = paramsArray[0];
            params = paramsArray[1];
            var link = getUrl(params);
            
			var data = {
				'action': 'loadParoles',
				'paged': paged,
                'categorie': category,
                'mot-clef': query,
                'link': link
            };
            loadAjaxParoles(data, url);
		});
    }
    // Contribuez
    if($('body').hasClass('page-template-contribuer')){
        function loadAjaxContributions(data, url){
			$.ajax({
				url: ajaxurl,
				data: data, 
				type: 'GET',
				beforeSend: function ( xhr ) {
					$('#post-container').remove();
					$('#onload').removeClass('d-none');
				},
				success: function( data ){
					if( data ) {
						$('#ajax-container').html(data);
                        
                        if (url != null) {                            
                            window.history.pushState('', '', url);
                        }
					}
				}
			});
		}
        
        // pagination
        $(document).on('click','.page-numbers li a',function(e){
			e.preventDefault();
			
            if ($(this).hasClass('prev')) {
                var paged = parseInt($('.pagination').attr('data-page')) - 1;
            } else if ($(this).hasClass('next')) {
                var paged = parseInt($('.pagination').attr('data-page')) + 1;
            } else {                
                var paged = $(this).text();
            }
            
            var url = $(this).attr('href').split('?')[0];
            var link = getUrl();
            
            var data = {
				'action': 'loadContributions',
				'paged': paged,
                'link': link
            };
            loadAjaxContributions(data, url);
		});
    }

    
    // UTILS
    function getUrl(params) {
        if (paged == null) {
            paged = 1;
        } else {                
            paged = parseInt(paged);
        }
        var currentUrl = window.location.href;
        var cleanedUrl = currentUrl.split('?')[0].split('page/')[0];

        var urlParams = '';
        var i = 0;

        $(params).each(function(i, element) {
            if (element.value.length) {                    
                if (i == 0) {
                    urlParams += '?' + element.name + '=' + element.value;
                } else {
                    urlParams += '&' + element.name + '=' + element.value;
                }
            }

            ++i;
        });
        var completeUrl = cleanedUrl + urlParams;

        return completeUrl;
    }
    
    // SESSIONSTORAGE
    function setSessionStorage(name) {            
        var url = window.location.href;
        var params = url.split('?')[1];
        
        if (params != null) {            
            sessionStorage.setItem(name, '?' + params);
        } else {
            sessionStorage.setItem(name, '');
        }
        console.log(sessionStorage.getItem(name));
    }
    
    function getSessionStorage() {
        var body = $('body');
        
        if (body.hasClass('single-aide')) {
            var params = sessionStorage.getItem('aidesParams');
        } else if (body.hasClass('single-offre-d-emploi')) {
            var params = sessionStorage.getItem('emploisParams');
        } else if (body.hasClass('single-document')) {
            var params = sessionStorage.getItem('documentsParams');
        } else if (body.hasClass('single-elu') || body.hasClass('single-ccb') || body.hasClass('single-ceser')) {
            var params = sessionStorage.getItem('elusParams');
        }
        
        if (params != null) {
            console.log(params);
            setUrlParams(params);
        }
    }
    
    function setUrlParams(params) {
        var btn = $('#btn-listBack');
        
        if (btn.length) {
            var currentHref = btn.attr('href');
            btn.attr('href', currentHref + params);
        }
    }



});